import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoginned: (localStorage.token !== undefined && localStorage.token !== ''),
    settings: {},
    license: {
      type: 'Лицензия не действительна',
      organization: '',
      issued_date: '01.01.2020',
    },
    currentState: '',
    wsMessage: null,
    wsList: {},
    wsIndex: 0,
    timezone: Number((localStorage.getItem('timezone') || 3)),
    timezones: [
      {
        title: 'UTC',
        offset: 0,
      },
      {
        title: 'Europe/Moscow',
        offset: 3,
      },
      {
        title: 'Asia/Novokuznetsk',
        offset: 7,
      },
      {
        title: 'Asia/Jakarta',
        offset: 7,
      },
    ],
    alertNames: [],
    isModalOpen: {
      gridColumns: false,
      user: false,
      information: false,
      confirm: false,
      reason: false,
      error: false,
      measurement: false,
      measurementFilter: false,
      functioningFilter: false,
      failureFilter: false,
      monitoringFilter: false,
      monitoringReports: false,
      resetFilter: false,
    },
    configFile: null,
  },
  getters: {
    timezoneTitle: (state) => state.timezones.filter((item) => item.offset === state.timezone)[0].title,
  },
  mutations: {
    setSettings(state, value) {
      state.settings = value;
    },
    setCurrentState(state, value) {
      state.currentState = value;
    },
    setLoginned(state, value) {
      state.isLoginned = value;
    },
    wsGetMessage(state, data) {
      state.wsMessage = data;
    },
    wsSend(state, payload) {
      state.wsIndex += 1;
      state.wsList[payload.index] = {
        resolve: payload.resolve,
        reject: payload.reject,
        wait: payload.wait,
      };
    },
    setTimezone(state, offset) {
      state.timezone = offset;
      localStorage.setItem('timezone', offset);
    },
    setAlertNames(state, obj) {
      state.alertNames = obj;
    },
    openModal(state, type) {
      if (state.isModalOpen[type] !== undefined) {
        state.isModalOpen[type] = true;
      }
    },
    closeModal(state, type) {
      if (state.isModalOpen[type] !== undefined) {
        state.isModalOpen[type] = false;
      }
    },
    setLicense(state, value) {
      state.license = value;
    },
    setConfigFile(state, value) {
      state.configFile = value;
      sessionStorage.setItem('configFile', JSON.stringify(value));
    },
  },
});
